import React, {useRef, useState, useEffect} from 'react'
import { useTranslation } from 'react-i18next'
import PageHeader from '@/layouts/components/PageHeader'
import { Col, Form, Row } from 'react-bootstrap'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import AirportSelection from '../Trips/Request/components/AirportSelection'
import moment from 'moment'
import MonthsSelection from '../Trips/Request/components/MonthsSelection'
import LoadingButton from '@/shared/components/LoadingButton'
import RoomSelection from '../Trips/Request/components/RoomSelection'
import { useAuth } from '@/services/Auth'
import LOCATION from '@/constants/Location'
import { formatRecord } from '@/utility'
import AppModal from '@/shared/components/AppModal'
import BudgetSelection from '../Trips/Request/components/BudgetSelection'
import TravelMaxPremium from '../Banners/TravelMaxPremium'

function Preferences () {
  const { t } = useTranslation()
  const auth = useAuth()

  const requestStore = useRef({})
  const { current: requests } = requestStore

  const [loading, setLoading] = useState(false)
  const [preferences, setPreferences] = useState(auth.user?.preferences)
  const [maxSelectedBudget, setMaxSelectedBudget] = useState(preferences?.budget ?? [0, 2500])

  const [showModal, setShowModal] = useState(false)
  const [modalTitle, setModalTitle] = useState(null)
  const [modalBody, setModalBody] = useState(null)
  const [confirmText, setConfirmText] = useState(null)
  const [cancelText, setCancelText] = useState(null)
  const [dealTypes, setDealTypes] = useState([]);

  const months = moment.months()

  const schema = yup.object().shape({
    where_from: yup.array()
      .min(1, t('pages.trips.form.validation_message.choose_one_airport'))
      .of(yup.object().shape({
        value: yup.number()
          .required(t('pages.trips.form.validation_message.is_required', { attribute: t('pages.trips.form.labels.where_from.title') })),
        label: yup.string(),
      })),
    budget: yup.array()
      .required(t('pages.trips.form.validation_message.is_required', { attribute: t('pages.trips.form.labels.max_budget.title') })),
    rooms: yup.array().of(yup.object().shape({
      adults: yup.number()
          .transform((value) => (isNaN(value) ? undefined : value))
          .required(t('pages.trips.form.validation_message.is_required', { attribute: t('pages.trips.form.labels.who_is_traveling_adults.adults') })),
      children: yup.number().transform((value) => (isNaN(value) ? undefined : value)).nullable(),
      children_ages: yup.array()
          .typeError(t('pages.trips.form.validation_message.is_required', { attribute: t('pages.trips.form.labels.who_is_traveling_adults.children_age') }))
          .when(['children'], function (children, schema) {
            return children && children != 0 ?
                schema.of(yup.number()
                    .required(t('pages.trips.form.validation_message.is_required', { attribute: t('pages.trips.form.labels.who_is_traveling_adults.children_age') }))
                    .transform((value) => (isNaN(value) ? undefined : value)))
                : schema.nullable()
          })
    }))
  })

  const {
    handleSubmit,
    register,
    watch,
    setValue,
    unregister,
    reset,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      min_budget: preferences?.budget[0],
      max_budget: preferences?.budget[1],
      where_from: preferences?.airports?.slice(0, Math.min(2, preferences?.airports?.length)).map(formatRecord) ?? [],
      where_to: preferences?.locations.map(formatRecord) ?? [],
      holiday_types: preferences?.holiday_types.map(formatRecord) ?? [],
      favourite_months: preferences?.favourite_months?.map(_ => `${_}`) ?? [],
      receive_deals_on_email: preferences?.receive_deals_on_email,
      adults: preferences?.adults,
      children: preferences?.children == 0 ? null : preferences?.children,
      children_ages: preferences?.children_ages ?? [],
      rooms: preferences.rooms ?? [],
      deal_emails_to_receive: preferences?.deal_emails_to_receive ?? [],
    },
    resolver: yupResolver(schema),
  })

  const savePreferences = (values) => {

    setLoading(true)
    auth.postRequest(`${LOCATION.PROFILE.PREFERENCES_API.path}`, {
      min_budget: parseInt(values?.budget[0]),
      max_budget: parseInt(values?.budget[1]),
      favourite_months: values?.favourite_months,
      where_from: values?.where_from?.map(_ => _.value),
      adults: values.rooms.reduce((acc, room) => acc + room.adults, 0),
      children: values.rooms.reduce((acc, room) => acc + room.children, 0),
      children_ages: values.rooms.reduce((acc, room) => acc.concat(room.children_ages), []).filter(_ => _ !== null),
      rooms: values?.rooms,
      receive_deals_on_email: values?.receive_deals_on_email,
      deal_emails_to_receive: values?.deal_emails_to_receive,
      long_haul_airports: values?.long_haul_airports?.map(_ => _.value)
    })
      .then(response => {
        setLoading(false)

        let nextUpdate = response.data?.data?.next_deals_update?.find(_ => _.type_id === 1)

        setModalTitle(t('pages.profile.notifications.preference_updated.title'))
        setModalBody(t('pages.profile.notifications.preference_updated.body', { nextUpdateDate: moment(nextUpdate.next_update).format(t('common.formats.short_date')) }))
        setConfirmText(t('common.dialogs.actions.okay'))
        setShowModal(true)

      })
      .catch(error => {
        setLoading(false)
      })
  }

  const handleConfirm = () => {
    setShowModal(false)
  }
  const handleCancel = () => {
    setShowModal(false)
  }

  const getDealTypes = (search) => {

    requests.deal_types && requests?.deal_types?.abort && requests.deal_types.abort()

    return new Promise((resolve, reject) => {
      requests.deal_types = auth.getRequest(LOCATION.DEALS.DEAL_TYPES.path, { search })

      requests.deal_types.then(response => {
        resolve(response?.data?.map(formatRecord))
      })
          .catch(error => reject(error))
    })
  }

  useEffect(() => {
    getDealTypes().then(response => {
      setDealTypes(response)
    })
  }, [])

  const receiveDealOnEmail = watch('receive_deals_on_email');

  useEffect(() => {
    if (receiveDealOnEmail && !preferences.deal_emails_to_receive) {
      setValue('deal_emails_to_receive', dealTypes?.map(_ => _.id));
    }
    if (!receiveDealOnEmail && preferences.deal_emails_to_receive) {
      setValue('deal_emails_to_receive', []);
    }
  }, [receiveDealOnEmail]);

  if (auth.user?.user_type !== 'travel_max') {
    return <TravelMaxPremium/>
  }

  return (
    <>
      <AppModal
        show={showModal}
        title={modalTitle}
        body={modalBody}
        confirmText={confirmText}
        cancelText={cancelText}
        handleConfirm={handleConfirm}
        handleCancel={handleCancel}
      />
      <PageHeader
        title={t('pages.profile.preferences')}
      />
      <div className="container px-4">
        <p
          className="mt-4 mb-0 w-100 text-center">{t('pages.profile.adjust_your_preferences_to_find_perfect_holidays')}</p>
        <form noValidate onSubmit={handleSubmit(savePreferences)}>
          <Row className="mt-3">
            <Col className="col-12 col-md-6 p-1 p-md-4">
              <p
                  className="request-process-heading mb-1">{t('pages.trips.steps.where_from.single_form_heading')}<span
                  className="text-danger">*</span></p>
              <AirportSelection
                  errors={errors}
                  register={register}
                  unregister={unregister}
                  reset={reset}
                  setValue={setValue}
                  selectedAirports={preferences?.airports?.slice(0, Math.min(2, preferences?.airports?.length)).map(formatRecord)}
                  controlled={true}
                  smallTextLabel={true}
              />
              <hr/>
              <p>Long Haul Airports</p>
              <AirportSelection
                  errors={errors}
                  register={register}
                  unregister={unregister}
                  reset={reset}
                  setValue={setValue}
                  selectedAirports={preferences?.long_haul_airports?.slice(0, Math.min(2, preferences?.long_haul_airports?.length)).map(formatRecord)}
                  controlled={true}
                  smallTextLabel={true}
                  holidayType={4}
                  fieldName="long_haul_airports"
              />
              <div className="mt-4 mb-5">

                <p className="mb-3">{t('pages.trips.steps.budget.total_budget_statement', {
                  min: maxSelectedBudget[0],
                  max: maxSelectedBudget[1],
                  currency: auth.user.currency.symbol
                })}</p>
                <BudgetSelection
                    errors={errors}
                    setValue={setValue}
                    values={preferences}
                    setMaxBudget={setMaxSelectedBudget}
                    currency={auth.user.currency.symbol}
                />
              </div>
              <div className="mt-4 mb-5">
                <p
                    className="request-process-heading mb-2">{t('pages.profile.email_subscription')}</p>

                <Form.Check
                    inline
                    label={t('pages.profile.form.labels.receive_deals_on_email')}
                    id="receive_deals_on_email"
                    name="receive_deals_on_email"
                    {...register('receive_deals_on_email')}
                />
              </div>
              {
                  receiveDealOnEmail && (
                      <div className="mt-4 mb-5" key={receiveDealOnEmail}>
                        <p
                            className="request-process-heading mb-2">{t('pages.profile.deal_emails_to_receive')}</p>

                        {
                          dealTypes?.map((dealType, index) => (
                              <Form.Check
                                  key={index}
                                  inline
                                  label={dealType.label}
                                  id={`deal_emails_to_receive_${dealType.id}`}
                                  name={`deal_emails_to_receive_${dealType.id}`}
                                  value={dealType.id}
                                  checked={!!watch(`deal_emails_to_receive`)?.includes(dealType.id)}
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      setValue('deal_emails_to_receive', [...getValues('deal_emails_to_receive'), dealType.id])
                                    } else {
                                      setValue('deal_emails_to_receive', getValues('deal_emails_to_receive').filter(_ => _ !== dealType.id))
                                    }
                                  }}
                              />
                          ))
                        }

                      </div>
                  )
              }
            </Col>
            <Col className="col-12 col-md-6 p-1 p-md-4">

              <MonthsSelection
                  errors={errors}
                  months={months}
                  register={register}
                  favouriteMonths={preferences?.favourite_months}
              />
              <RoomSelection
                  register={register}
                  unregister={unregister}
                  errors={errors}
                  watch={watch}
                  setValue={setValue}
                  rooms={preferences?.rooms ?? []}
              />
            </Col>
          </Row>
          <Form.Group className="d-grid gap-2 my-5">
            <LoadingButton
              className="auth-btn text-uppercase fw-bold text-white heading-text"
              loading={loading}
              variant="primary"
              titleTranslationKey="common.buttons.save"
              type="submit"
            />
          </Form.Group>
        </form>
      </div>
    </>
  )
}

export default Preferences