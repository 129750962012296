const Trips = {
  title: 'Trips',
  saved_trips: 'My Saved Trips',
  trip_by_range: '{{startPrice}} - {{endPrice}} Trips',
  under_price_deals: 'Under {{price}}',
  under_price: 'Under {{underPrice}} Trips',
  request_a_trip: 'Request a Trip',
  confirmation_message: 'confirmation message',
  weekend_trip: 'Weekend Trips',
  long_haul: 'Long Haul',
  beach_deals: 'Beach Vibes',
  start_your_journey: 'Start your Journey',
  travel_light_member_trip_saving_statement: 'Travel Lite members can only save one trip. Travel Max members can save as many trips as they like!',
  flight_booked: 'Flight Booked',
  hotel_booked: 'Hotel Booked',
  hotel_sold_out: 'Hotel Sold Out',
  flight_sold_out: 'Flight Sold Out',
  hotel_expired: 'Hotel Expired',
  flight_expired: 'Flight Expired',
  free_custom_trips: {
    title: 'Enjoy 3 Free Custom Trip Plans every year!',
    claimed_trip: 'You’ve claimed {{count}} Trips so far this year.',
    claimed_trip_one: 'You’ve claimed One Trip so far this year.',
    purchase_button_heading: 'Purchase more trip plans',
  },
  select_options: {
    sort_by_price: 'Sort by Price',
    sort_by_date: 'Sort by Departure Date',
  },
  form: {
    title: 'Complete this form and one of our experts will be in touch within 48 hours',
    validation_message: {
      is_required: '{{attribute}} is required',
      choose_one_month: 'Please choose at least one Month',
      choose_one_date: 'Please choose at least one Date',
      choose_valid_date_range: 'Please choose a valid date range, You must select start date and end date, you can also choose more than one range',
      choose_one_airport: 'Please choose at least one Airport',
      choose_one_destination: 'Please choose at least one Destination',
      choose_one_holiday_type: 'Please choose at least one Holiday Type',
      choose_one_location_feature: 'Please choose at least one location feature',
    },
    labels: {
      when: {
        night_one: 'One Night',
        night: '{{count}} Nights',
        when: 'When can you travel?',
        how_long_for: 'And for how long?',
        how_long: 'How long for?',
        allow_flexi_duration: 'If we cannot find the exact duration you have selected, would you like us to find trip that are +/- 1 day of your chosen duration?',
      },
      dates: {
        months: 'Which Month(s)?',
        month: 'Months(s)?',
        date: 'Date(s)?',
        dates_label: 'Let us know all the dates you are available for this trip',
      },
      where: {
        title: 'Where would you like to go?',
        subtitle: 'Enter multiple locations here if you’re not sure!'
      },
      where_to_avoid: 'Where would you like to avoid?',
      who_is_traveling_adults: {
        heading: 'Who is travelling?',
        adults: 'Adults (16+)',
        children: 'Children (0-15)',
        children_label_count: 'Child {{count}} age',
        children_age: 'Child age',
      },
      age_of_children: 'Ages of Children (if any)',
      preferred_airports: {
        title: 'Preferred Airports?',
        subtitle: 'List as many as are possible for you',
      },
      max_budget: {
        title: 'Maximum Budget for the trip?',
        subtitle: 'This is for your whole groups flights and hotel stay.',
      },
      trip_specifics: {
        title: 'Any specifics to make the trip special?',
        subtitle: 'Lets tailor this trip to you!',
      },
      room_selection: {
        title: 'Room Selection',
        description: 'Please select the number of guests and rooms you would like to book for',
        subtitle: 'Select your room type',
        adults: 'Adults',
        children: 'Children',
        room: 'Room {{room}}',
        remove_room: 'Remove Room',
        add_room: 'Add Room',
      },
      hotels_filters: {
        hotels: "Hotel Filters",
        food: "Food Filters",
      }
    },
    placeholder: {
      type_here: 'Type Here...',
    },
  },
  buttons: {
    purchase_more_plans: 'Purchase more trip plans',
    any_holiday_break_city: 'Any City Break',
    any_holiday_break_beach: 'Any Beach Break',
    any_holiday_break_party: 'Any Party Break',
    see_results: 'See Results',
    find_me_a_deal: 'Find me a deal',
    start_your_journey: 'START YOUR JOURNEY',
    book_now: 'Book Now',
    booked: 'Booked',
    sold_out: 'Sold Out',
    expired: 'Expired',
    refresh_prices: 'Refresh Prices',
    trip_is_expired: 'This trip is expired',
  },
  budgets: {
    upto: 'Up to £{{budget}}',
  },
  notifications: {
    book_flight_confirm: {
      title: 'Are you Sure?',
      body: 'Mark this flight as booked?',
    },
    remove_from_booked_confirm: {
      title: 'Are you Sure?',
      body: 'Remove this from booked list?',
    },
    book_hotel_confirm: {
      title: 'Are you Sure?',
      body: 'Mark this hotel as booked?',
    },
    request_sent: {
      title: 'We are looking for the best deals!',
      body_normal: 'Your deals will be ready <b>{{estimated}}</b>. There\'s no need to wait around here. Feel free to explore other sections of the website and we\'ll notify you as soon as your deals are ready!',
      body_high_demand: 'This usually takes less than a minute,but we are unusually busy right now, please come back and check your notifications <b>{{estimated}}</b> when you will have some results. Please <a href="https://thetravelmum.com/contact-us/" target="_blank">get in touch</a> if you are having issues with your search',
      link_text: 'Back to members area',
    },
    request_sent_sent: {
      title: 'WE HAVE RECEIVED YOUR REQUEST.',
      body: 'We will research your options and be in touch within the next 48 hours!',
      link_text: 'Back to members area',
    },
    trip_removed: {
      title: 'Trip removed',
      body: 'The trip has been removed from you saved trips',
      link_text: 'Okay',
    },
    remove_trip: {
      title: 'Remove Trip',
      body: 'Are you sure you want to remove this trip?',
      link_text: 'Okay',
    },
    no_trips_found: {
      title: 'No saved Trips Found!',
      body: 'You have not saved any Trips Yet.',
      link_text: 'Okay',
    }
  },
  steps: {
    budget: {
      title: 'Total Budget?',
      form_heading: 'Total Budget for all Party Members',
      budget_sub_heading: 'For example £150pp for two adults - Please enter £300 as the total budget.',
      budget_statement: 'Your Budget is set between {{currency}}{{min}} - {{currency}}{{max}}',
      total_budget_statement: 'Total budget for your next trip (for all travellers) - {{currency}}{{min}} - {{currency}}{{max}}',
    },
    holiday_type: {
      title: 'Holiday Type?',
      submit_error: 'Please select your holiday type',
      types: {
        city: 'City',
        beach: 'Beach',
        party: 'Party',
        surprise: 'Surprise Me!',
      }
    },
    where_from: {
      title: 'Where from?',
      form_heading: 'Add your preferred airports.',
      single_form_heading: 'Add your preferred airport.',
      from_alternative_airport: 'Alternative Airport',
      from_have_alternative_airport: 'Do you have an alternative airport?',
    },
    where_to: {
      title: 'Where to?',
      form_heading: 'Search for a location',
    },
    who_is_going: {
      title: 'Who’s going?',
      form_heading: '',
    },
    when: {
      title: 'When?',
      do_you_have_specific_dates: 'Select multiple periods of availability or specific dates',
      back_to_all_dates: 'Back to all dates',
      clear: 'Clear Selections',
      party_season_awareness_message: 'Please check when the main party season runs in your chosen destination',
      beach_season_awareness_message: 'Please check when the main tourist season runs in your chosen destination especially when searching outside of the peak summer months. Some resorts may close for winter',
    },
    what_will_make_it_special: {
      title: 'What will make it special?',
      form_heading: 'Please select any of the following that you would like to have at your hotel',
    },
    summary: {
      title: 'Summary',
      values: {
        budget_summary: '£{{min}} - £{{max}}',
        adults: '{{count}}x Adults',
        children: '{{count}}x Children',
        children_ages: '{{count}}',
      },
      step_headings: {
        budget: 'Budget?',
        holiday_type: 'What type of holiday?',
        where_from: 'Where from?',
        where_to: 'Where to?',
        who_is_going: 'Who’s going?',
        when: 'When?',
        what_will_make_it_special: 'What will make it special?',
      }
    },
    flight_options: {
      title: 'Flights Options',
    },
  },
  errors: {
    invalid_dates_with_selected_duration: 'Invalid date range selected. The date range you have set is shorter than the duration you would like to be away. Don\'t worry, you can edit it above. Select ALL the days you are available for this trip and we will find you the best value dates in that period'
  }
}
export default Trips