const Profile = {
  title: 'Profile',
  edit_profile: 'Edit Profile',
  edit_profile_form: 'Edit Profile form',
  preferences: 'Preferences',
  edit_your_profile_payment_details_subscription_here: 'Edit your profile, payment details and your subscription here.',
  adjust_your_preferences_to_find_perfect_holidays: 'Adjust your preferences so we can send you deals that are tailored for you!',
  payment_details: 'Payment Details',
  your_latest_notifications: 'Your Latest Trip Notifications',
  account_details: 'Account Details',
  verification_code_sent: 'We have sent a verification code to your email, Fill the code below to validate & update your email',
  email_subscription: 'Email Subscriptions',
  deal_emails_to_receive: 'Select the type of deal emails you would like to receive',
  buttons: {
    upload_new_image: 'Upload new image',
    send_confirmation_email: 'SEND CONFIRMATION EMAIL',
    validate_and_update: 'Validate and Update',
  },
  form: {
    labels: {
      favourite_airports: 'Favourite Airports',
      favourite_locations: 'Favourite Locations',
      favourite_holiday_types: 'Favourite Holiday Types',
      favourite_months: 'Favourite Months to Travel',
      receive_deals_on_email: 'Receive Deals on your Email',
    }
  },
  notifications: {
    email_updated: {
      title: 'Email Updated',
    },
    preference_updated: {
      title: 'Preferences Updated',
      body: 'Your preferences were updated. You will now receive weekly updates with some great travel deals. View these in your members area or email inbox.  Check back in  {{nextUpdateDate}}',
    },
    remove_image: {
      title: 'Remove Image',
      body: 'Are you sure you want to remove your Customized avatar image?',
    }
  }
}

export default Profile